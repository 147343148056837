/**
 * React.
 */
import React from "react"

/**
 * Gatsby.
 */
import { graphql } from "gatsby"

/**
 * Layout.
 * Used for basic page structure.
 */
import Layout from "../components/layout"

/**
 * SEO.
 * Used for page SEO content.
 */
import Seo from "../components/seo"

/**
 * Languages.
 * Text for translated page content.
 */
import LangEN from "../languages/LangEN"
import LangFR from "../languages/LangFR"

const SearchResults = ({ data, location, searchresults = '' }) => {

  /* Get URL params */
  const params       = new URLSearchParams( location.search )
  let searchQuery    = params.get( 's' )
  let currentPage    = params.get( 'p' )

  /* Set display results per page count */
  let resultsPerPage = 3
  let resultsCount = ''

  /* Set prev/next count */ 
  let prevResults = ( currentPage - ( resultsPerPage + 1 ) ) + 1
  let nextResults = 0

  /* Set default value */
  let countSearchResults = 0

  /* Set maximum display characters for each result */
  const itemMaxCharacters = 150

  /* Set default language */
  let searchLang = ''

  /* Set default url */
  let searchUrl  = '/search/'

  /* Get URL so we can display page with chosen language */
  let window_url = ( typeof window !== 'undefined' ) ? window.location.href : ''

  /* Switch menu based on language value in url */
  if ( window_url.indexOf( "/fr/" ) > -1 ) {
    searchLang = 'fr'
    searchUrl  = '/fr/search/'
  }

  /* Translate string to chosen language */
  function translate_string( str = '' ) {
    /* Set default language */
    let lang = LangEN

    /* Get URL so we can display page with chosen language */
    let window_url = ( typeof window !== 'undefined' ) ? window.location.href : ''

    /* Switch menu based on language value in url */
    if ( window_url.indexOf( "/fr/" ) > -1 ) {
      lang = LangFR
    }

    /* Convert string to language */
    for( var i in lang ) {
      if ( str === i ) {
        return lang[i]
      }
    }
  }

  /* Set Searchbar */
  const SearchBar = () => (
    <div className="search-bar row align-items-end">
    
      {/* Search form */}
      <form
        className="search-bar-form"
        action={searchUrl}
        method="get"
        autoComplete="off"
      >
        <div className="row align-items-end">
          <div className="form-group">
      
            {/* Input field */}
            <input
              type="text"
              id="search-field"
              className="search-field form-control"
              placeholder=""
              name="s"
              style={{ marginRight: `5px`, float: `left` }}
              defaultValue={searchQuery}
            /> 
      
            {/* Submit button */}
            <button 
              className="btn btn-secondary"
              style={{ marginBottom: `20px`, }}>{translate_string( 'search' )}</button> 
      
          </div>
        </div>
      </form>
    </div>
  );

  /* If we don't have a search query, inform user to provide search text */
  if ( ! searchQuery ) {
    return ( 
      <Layout>
        <Seo title={translate_string( 'search' )} />
        <div>
          {/* Title */}
          <h1>Search</h1>
          {/* Searchbar */}
          <SearchBar />
        </div>
      </Layout>
    )
  } 

  /* If we have results */
  if ( data.allWpContentNode.edges ) {
    /* Loop for each search result */
    data.allWpContentNode.edges.forEach( search_result, countSearchResults )
    /* If we dont have a result */
    if ( ! searchresults ) {
      /* Inform the user */
      searchresults = translate_string( 'no results found' );
    }
  }

  /* This function is called for each search result */
  function search_result ( item ) {

    /* If we have a title and a link */
    if ( item.node.title && item.node.uri ) {

      /* Get post url so we can check for language ( eg. check for /fr/ etc )*/
      let post_language = item.node.uri.split( '/' )

      /* Set result */
      let result = false

      /* If we have a language value in the post url */
      if ( searchLang ) {
        if ( searchLang.indexOf( post_language[1] ) !== -1 ) {
            /* Set result item */
            result = item
        } 
      }
      
      /* 
       * If we do not have a post language value in the url 
       * or post language section in url is larger than 2 characters ( meaning it's not a language value ) 
       */
      if ( ! searchLang ) {
        if ( ! post_language[1].length || post_language[1].length > 2 ){
          /* Set result item */
          result = item
        }
      }

      /* If we have a result */
      if ( result ) {
        /* Set item as result */
        item = result
      } 
      /* 
       * Else return, 
       * ( we don't need to go any further for this item ) 
       */
      else {
        return
      }
    
      /**
       * We only want valid characters [a-z0-9_] in the search str.
       * With javascript the best comparison method at the moment is to remove any str 
       * chars you don't want and compare lengths of original search str and edited search str.
       */
      let alphanumeric_str = searchQuery.replace( /\W/g, '' )
      if ( searchQuery.length !== alphanumeric_str.length ) {
        return
      }
    
      /**
       * If WordPress post title or content matches our searchQuery.
       */ 
      if ( ( item.node.title.toLowerCase().search( searchQuery.toLowerCase() ) > -1 ) 
        || ( item.node.content.toLowerCase().search( searchQuery.toLowerCase() ) > -1 ) ) {

        /**
         * Count search results. 
         */  
        countSearchResults++ 
        
        /**
         * Set default currentPage value.
         */
        currentPage = ( Number( currentPage ) > -1 ) ? currentPage : 0

        /**
         * Limit results per page.
         */
        if ( countSearchResults > Number( currentPage ) 
              && countSearchResults < ( Number( currentPage ) + ( Number( resultsPerPage ) + 1 ) ) ) {  

          /**
           * We need to change the url for /Home page to /.
           * ( Why is that? The /Home page is the websites /Index page )
           */
          if ( '/home/' === item.node.uri ) {
            item.node.uri = '/';
          }

          /**
           * Search result HTML formatting.
           */
          searchresults = searchresults + '<div class="search-result">'
          searchresults = searchresults + '<a href="' + item.node.uri + '"><span class="title">' + item.node.title + '</a><br />'
          searchresults = ( item.node.content.length > itemMaxCharacters ) ? searchresults + item.node.content.replace(/(<([^>]+)>)/gi, "").slice( 0, itemMaxCharacters ) + '...' : searchresults + item.node.content
          searchresults = searchresults + '<div style="text-align:right"><a class="btn btn-outline-success" href="' + item.node.uri + '">' + translate_string( 'view' ) + ' &#8250;</a></div>'
          searchresults = searchresults + '</div>'

          /**
           * Set next results count.
           */
          nextResults = countSearchResults
        }
      }
    }
  }

  /* Search navigation buttons ( eg. Previous/Next buttons ) */
  function searchNavigationButtons() {

    /* If we have no search results */
    if ( translate_string( 'no results found' ) === searchresults ) {
      /* return empty, we don't need a search navigation item */
      return '';
    }

    /* If we have search results but not enough for navigation */
    if ( countSearchResults <= resultsPerPage ) {
      /* return empty, we don't need to display Previous/Next buttons */
      return '';
    } 
    
    /* Start the container for navigation */
    let nav = '<div style="text-align:center;">' 

    /* If previous results are less than zero */
    if ( prevResults < 0 ) {
      /* Disable the previous button, it's not needed */
      nav = nav + '<a href="?s=' + searchQuery + '&p=0" class="btn btn-outline-success prev-results disabled">&#8249; ' + translate_string( 'previous' ) + '</a>&nbsp;'
    } else {
      /* Else, enable the previous button */
      nav = nav + '<a href="?s=' + searchQuery + '&p=' + prevResults + '" class="btn btn-outline-success prev-results">&#8249; ' + translate_string( 'previous' ) + '</a>&nbsp;'
    }             
           
    /* If total next results don't fill a full page ( eg. displaying 9/10 instead of 10/10 results ) */
    if ( nextResults % resultsPerPage === 0 ) {
      /* Disable the next button, it's not needed */
      nav = nav + '<a href="?s=' + searchQuery + '&p=' + nextResults + '" class="btn btn-outline-success next-results" style="">' + translate_string( 'next' ) + ' &#8250;</a>'
    } else {
      /* Else, enable the next button */
      nav = nav + '<a href="?s=' + searchQuery + '&p=' + nextResults + '" class="btn btn-outline-success next-results disabled" style="">' + translate_string( 'next' ) + ' &#8250;</a>'
    } 
    
    /* End the container for navigation */
    nav = nav + '</div>' 

    /* Return search navigation item */
    return nav
  }

  /* Display results count ( eg. Displaying 1-3 out of 5 result(s) ) */
  function getResultsCount() {

    /* Format the text to display */
    let result = translate_string( 'displaying' ) + ' ' + ( Number( currentPage ) + 1 ) + '-' + nextResults + ' ' + translate_string( 'out of' ) + ' ' + countSearchResults + ' ' + translate_string( 'result(s)' );

    /* If current page count exceeds available results count */
    if ( ( Number( currentPage ) + 1 ) > Number( countSearchResults ) ) {
      result = ''
    }

    /* Return result */
    return result
  }

  resultsCount = getResultsCount()

  return (
    <Layout>

      {/* SEO details */}
      <Seo title={translate_string( 'search' )} /> 

      {/* Page title */}
      <h1>{translate_string( 'search' )}</h1>

      <div>

        {/* Searchbar */}
        <SearchBar/>

        {/* Search results */}
        <div className="search-count">
          {resultsCount}
        </div>
        <div dangerouslySetInnerHTML={{ __html: searchresults + searchNavigationButtons() }} /> 

      </div>
    </Layout> 
  )
}

/* Get allWpContentNode data */
export const query = graphql`
{
  allWpContentNode {
    edges {
      node {
        ... on WpPage {
          id
          content
          title
          uri
        }
        ... on WpPost {
          id
          content
          title
          uri             
        }       
      }
    }
  }
}`

export default SearchResults
